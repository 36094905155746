import React from 'react'
import { graphql,Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import ButtonLink from "../components/ButtonLink/ButtonLinik";
import Slider from "react-slick"
import Img from "gatsby-image";

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const gallery = get(this, 'props.data.allContentfulGallery.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      // centerMode: true,
      // centerPadding: '6%',
      arrows: true,
    };
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <div className="wrapper">
            <p>{author.node.bio.bio}</p>
            <div
                dangerouslySetInnerHTML={{
                  __html: author.node.bio.childMarkdownRemark.html,
                }}
            />
            <ButtonLink link="/about" text='もっと見る' />

            <h2 className="section-headline">Recent articles</h2>
            <ul className="article-list">
              {posts.slice(0,3).map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
            <ButtonLink link='/blog' text='もっと見る' />

            <h2 className="section-headline">Gallery</h2>

            <Slider {...settings}>
              {gallery.slice(0,5).map(({ node }) => {
                return (
                    <Img alt="" fluid={node.photo.fluid} />
                )
              })}
            </Slider>
            <ButtonLink link='/gallery' text='もっと見る' />

          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "YYYY.MM.DD")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: PAD) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulGallery(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          photo {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: PAD) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            childMarkdownRemark {
              html
            } 
          }
          bio {
            childMarkdownRemark {
              html
            }
          }           
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
